.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.footer {
  background-color: #0602f6; /* Light gray background */
  text-align: center;
  padding: 1rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Center the login form */
.login-form {
  width: 100%;
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.login-form h3 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form .form-control {
  border-radius: 4px;
  margin-bottom: 15px;
}

.login-form button {
  width: 100%;
  padding: 10px;
}

.login-form .text-danger {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}
