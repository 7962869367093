.full-height {
    min-height: 100vh; /* Ensure full viewport height */
    display: flex;
    flex-direction: column;
  }
  
  .content {
    flex: 1; /* Take up remaining space */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer {
    background-color: #f41111; /* Light gray background */
    text-align: center;
    padding: 1rem;
  }

  /* Home.css */
.card {
  background-color: #f8f9fa; /* Light background for the card */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.card:hover {
  transform: translateY(-10px); /* Slight lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.card p {
  font-size: 2rem; /* Larger font size for the count */
  font-weight: bold; /* Bold font */
  color: #333; /* Darker text color */
}

.card h5 {
  font-size: 1.25rem; /* Slightly larger font size for headings */
  color: #555; /* Medium text color */
}

  