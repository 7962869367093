/* Overall container styling */
.container {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Header styling */
  h1 {
    color: #343a40;
    border-bottom: 2px solid #28a745;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  /* User details section styling */
  .user-details {
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .user-details h3 {
    color: #28a745;
    margin-bottom: 15px;
  }
  
  .user-detail-item {
    background-color: #f1f1f1;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #ced4da;
    transition: background-color 0.3s ease;
  }
  
  .user-detail-item p {
    margin: 0;
  }
  
  .user-detail-item:hover {
    background-color: #e9ecef;
  }
  
  /* Table styling */
  .table {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .table th {
    background-color: #28a745;
    color: #ffffff;
    font-weight: bold;
  }
  
  .table td {
    vertical-align: middle;
  }
  
  .table img {
    border-radius: 4px;
  }
  
  /* Total amount section styling */
  .total-amount {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .total-amount h3 {
    color: #28a745;
  }
  
  /* Action buttons styling */
  .action-buttons {
    margin-top: 20px;
  }
  
  .action-buttons .btn {
    margin-right: 10px;
  }
  
  .btn-success {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:hover {
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:hover {
    background-color: #138496;
    border-color: #117a8b;
  }
  
  .total-amount {
    font-size: 0.875rem; /* Smaller font size */
  }
  
  .total-amount .h5, .total-amount .h4 {
    font-size: 1rem; /* Adjust heading font sizes */
  }
  
  .text-muted {
    color: #6c757d; /* Muted color for headings */
  }
  
  .font-weight-bold {
    font-weight: 700; /* Bold text for values */
  }
  

  .additional-info {
    font-size: 1rem; /* Set base font size */
  }
  
  .additional-info h3 {
    margin-bottom: 1rem; /* Space below the heading */
    font-size: 1.25rem; /* Larger font size for the heading */
  }
  
  .dl {
    margin: 0;
  }
  
  .dt, .dd {
    margin-bottom: 0.5rem; /* Space between items */
  }
  
  .dt {
    font-weight: 600; /* Bold text for terms */
  }
  
  .dd {
    margin-left: 0; /* Remove default margin for descriptions */
  }
  