.view-category-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    margin-top: -120px; /* Reduce top margin here */
  }
  
  .text-center {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px; /* Adjust top margin to reduce spacing */
  }
  
  .category-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 600px;
  }
  
  .category-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .btn-delete {
    border: none;
    background: transparent;
    color: red;
    cursor: pointer;
    font-size: 16px;
    margin-left: 15px;
  }
  
  .btn-delete:hover {
    color: darkred;
  }
  