.form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 20px auto;
  }
  
  .form-title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .testimonial-form .form-group {
    margin-bottom: 1.5rem;
  }
  
  .testimonial-form .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #495057;
  }
  
  .testimonial-form .form-control {
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ced4da;
    font-size: 16px;
  }
  
  .testimonial-form .form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
  
  .testimonial-form .btn {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
  }
  
  .testimonial-form .img-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
  }
  