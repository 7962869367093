/* Addproduct.css */

/* Addproduct.module.css */

.product-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  
    margin-top: 20px; /* Add top margin */
  }
  
  /* Other styles remain the same */
  

/* Make class name more specific */
.add-product-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  
    margin-top: 20px; /* Add top margin */
  }
  .add-product-container form {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .add-product-container h1 {
    font-size: 24px;
    color: #333;
  }
  
  /* Additional styles */
  .add-product-container .form-group {
    margin-bottom: 1.5rem;
  }
  
  .add-product-container .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #495057;
  }
  
  .add-product-container .form-control {
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ced4da;
    font-size: 16px;
  }
  
  .add-product-container .form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
  
  .add-product-container .btn {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0.75rem 1.25rem;
    font-size: 16px;
    line-height: 1.5;
    transition: all 0.15s ease-in-out;
  }
  
  .add-product-container .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .add-product-container .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
  }
  
  .add-product-container .btn-block {
    width: 100%;
  }
  
  .add-product-container .mt-2 {
    margin-top: 0.5rem;
  }
  
  .add-product-container .mt-3 {
    margin-top: 1rem;
  }
  
  .add-product-container .mb-3 {
    margin-bottom: 1rem;
  }
  
  .add-product-container .text-center {
    text-align: center;
  }
  
  .add-product-container input[type="file"] {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
  }
  
  .add-product-container input[type="date"] {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
  }
  
  .add-product-container .image-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .add-product-container .image-previews .img-thumbnail {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  