/* ViewProduct.css */

/* Ensure the table takes the full width of its container */
.table-container {
    overflow-x: auto; /* Allow horizontal scrolling if content overflows */
  }
  
  /* Ensure table width is 100% */
  .table {
    width: 100%; /* Ensure the table spans the full width */
  }
  
  /* Optional: Set a minimum width for table cells to prevent overlap */
  .table th, .table td {
    min-width: 100px; /* Adjust this value as needed */
    white-space: nowrap; /* Prevent text from wrapping and causing column issues */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    overflow: hidden; /* Hide overflowed content */
  }
  
  /* Optional: Add styles for images */
  .table img {
    max-width: 100px; /* Adjust the max-width for images */
    height: auto; /* Maintain aspect ratio */
  }
  